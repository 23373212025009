$(document).ready(function () {
  $(document).on('click', '.switch-up', function () {
    var $this = $(this);
    var $input = $this.closest('.counter-switch').find('input');
    if ($input.val() <= 1) {
      $input.val(1)
    } else {
      $input.val(parseInt($input.val()) - 1)
    }
  })

  $(document).on('click', '.switch-down', function () {
    var $this = $(this);
    var $input = $this.closest('.counter-switch').find('input');
    $input.val(parseInt($input.val()) + 1)
  })

  $(document).on('click', '.btn-select', function (e) {
    e.preventDefault();
    $(this).parents('.grid-review:eq(0)').find('.btn-select').removeClass('selected').text('Select');
    $(this).addClass('selected').text('Selected')
  });

  $(document).on('click', '.btn-mobile-menu', function () {
    $('header nav').slideToggle();
  })

  $(document).on('click', '.thumbnail-slider .thumb', function () {
    $('.main-slider img').attr('src', $(this).find('img').data('src'));
  });
});
